main {
  background: #f5f5f7;
}

section {
  padding-bottom: 20rem;
}

.item-multi:hover{
  background-color: #f5f5f7 !important;
}

.simple-button:hover{
  background-color: #eee !important;
}